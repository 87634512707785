<template>
  <v-card>
    <v-card-title class="py-1">
      <h3 class="title">{{ $t('ecommerce.orders.details.products.title') }}</h3>
    </v-card-title>
    <v-card-text>
      <template v-for="p in products">
        <product :product="p"
                 :key="p.sku_code"
                 :country="country"
        ></product>
        <v-divider class="my-4"></v-divider>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>

import Product from "@/components/ecommerce/_partials/Product";

export default {
  name: "Products",

  components: {Product},

  props: {
    products: {
      type: Array
    },
    country: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>