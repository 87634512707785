<template>
  <v-card class="mb-10">
    <v-card-title class="py-1">
      <h4 class="title">{{ $t('ecommerce.orders.details.customer.title') }}</h4>
    </v-card-title>
    <v-card-text v-if="order.user">
      <p class="mt-3">
        <strong>{{ order.user.firstname }} {{ order.user.lastname }}</strong><br>
        <a target="_blank" :href="'mailto:' + order.user.email">{{ order.user.email }}</a><br>
        <a target="_blank" :href="'tel:' + order.user.phone">{{ order.user.phone }}</a>
      </p>
      <v-divider class="my-3"></v-divider>

      <h3>{{ $t('ecommerce.orders.details.oder_note') }}</h3>
      <p>
        <template v-if="order.order_note">
          {{ order.order_note }}
        </template>
        <template v-else>
          -
        </template>
      </p>

      <v-divider class="my-3"></v-divider>

      <h3 v-if="order.deliver === 'pickup'">
        {{ $t('ecommerce.orders.details.deliver.pickup') }}
      </h3>

      <template v-else>
        <h3>
          {{ $t('ecommerce.orders.details.deliver.shipment') }}
        </h3>
        <p>
          {{ order.user.address }}, {{ order.user.street_number }}<br>
          {{ order.user.zip_code }} {{ order.user.city }}
        </p>
        <p>
          <strong>{{ $t('ecommerce.orders.details.deliver.shipment_instruction') }}</strong><br>
          {{ order.user.address_info }}
        </p>

      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Customer",
  computed: {
    ...mapGetters({
      isLoading: 'ecommerce/loadingDetails',
      order: 'ecommerce/getOrder',
    }),
  }
}
</script>

<style scoped>

</style>