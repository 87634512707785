<template>

  <v-select
      v-model="localModel"
      :label="$t('commons.fields.status')"
      :items="allStatuses"
      item-text="label"
      item-value="value"
      :rules="requiredRules"
      :required="required"
      outlined
      dense
      multiple
      small-chips
      deletable-chips
  ></v-select>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "OrderStatuses",

  props: {
    statuses: Array,
    required: {
      type: Boolean,
      default: false
    },
    requiredRules: Array
  },

  computed: {
    ...mapGetters({
      allStatuses: 'ecommerce/getStatuses'
    }),

    localModel: {
      get() {
        return this.statuses
      },
      set(statuses) {
        this.$emit('update:statuses', statuses)
      }
    }
  },
}
</script>

<style scoped>

</style>