<template>
  <div>
    <v-icon @click="openHelpModal">{{ $t('icons.help') }}</v-icon>

    <v-dialog
        v-model="dialog"
        max-width="700px"
        persistent
    >
      <v-card>
        <v-card-title>
          {{ $t('ecommerce.orders.details.helps.title') }}

        </v-card-title>
        <v-divider class="mb-5"></v-divider>
        <v-card-text>
          <v-sheet v-for="status in allStatuses" :key="status.value">
            <template v-if="status.value !== 'cart'">
              <v-chip :color="status.color" label>{{ $t('ecommerce.orders.details.helps.' + status.value + '.title') }}
              </v-chip>
              <br>
              {{ $t('ecommerce.orders.details.helps.' + status.value + '.text') }}
              <v-divider class="my-3"></v-divider>
            </template>
          </v-sheet>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="close"
          >
            {{ $t('commons.actions.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "StatusHelp",
  data: () => ({
    dialog: false,
  }),

  computed: {
    ...mapGetters({
      allStatuses: 'ecommerce/getStatuses',
    })
  },

  methods: {
    openHelpModal() {
      this.dialog = true
    },

    close() {
      this.dialog = false
    },
  }
}
</script>

<style scoped>
.bordered {
  border: 1px solid #dddddd;
}

</style>