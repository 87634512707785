<template>
  <v-stepper alt-labels class="elevation-0 mt-5 d-print-none">
    <v-stepper-header>
      <v-stepper-step
          step="1"
          :complete="status === 'processing' || status === 'ready' || status === 'delivered'"
          :class="{'font-weight-bold active' : status === 'on_hold'}"
      >
        {{ $t('ecommerce.orders.statuses.on_hold') }}
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
          step="2"
          :complete="status === 'ready' || status === 'delivered'"
          :class="{'font-weight-bold active' : status === 'processing'}"
      >
        {{ $t('ecommerce.orders.statuses.processing') }}
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
          step="3"
          :complete="status === 'delivered'"
          :class="{'font-weight-bold active' : status === 'ready'}"
      >
        {{ $t('ecommerce.orders.statuses.ready') }}

      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
          step="4"
          :complete="status === 'delivered'"
      >
        {{ $t('ecommerce.orders.statuses.delivered') }}

      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  name: "OrderProgress",

  props: {
    status: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">

.active {

  .v-stepper__step__step {
    background-color: #4caf50 !important;
  }

  .v-stepper__label {
    color: #4caf50 !important;

  }
}
</style>