<template>
  <v-card class="mt-5">
    <v-card-text>
      <v-btn :loading="isDeleting" @click="deleteOrder" color="error">{{ $t('ecommerce.orders.actions.delete.button') }}</v-btn>

    </v-card-text>
  </v-card>

</template>

<script>
import {mapGetters} from "vuex";
import {EventBus} from "@/event-bus";

export default {
  name: "DeleteOrder",

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      isDeleting: 'ecommerce/isDeleting',
    }),
  },

  methods: {
    async deleteOrder() {
      await this.$confirm(this.$t('ecommerce.orders.actions.delete.confirm')).then(res => {
        console.log('confirm res ' , res)
        if (res) {
          this.$store.dispatch('ecommerce/deleteOrder', this.item.id)
              .then(resp => {
                console.log('then close dialog resp ', resp)
                EventBus.$emit('reload-orders')
              })
              .catch(err => {
                console.log('create error err ', err)
              })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>