<template>
  <v-row class="mt-3">
    <v-col cols="4">
      <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('ecommerce.orders.actions.find.placeholder')"
          clearable
          outlined
          dense
          flat
          hide-details
          :disabled="isLoading"
      ></v-text-field>
    </v-col>
    <v-col cols="8">
      <v-btn color="success"
             @click="doSearch"
             :disabled="!search || search.length === 0"
             :loading="isLoading"
      >{{  $t('commons.actions.search')  }}</v-btn>
    </v-col>
  </v-row>
</template>

<script>

import {mapGetters} from "vuex";
import i18n from "@/plugins/i18n";

export default {
  name: "SearchOrder",

  data() {
    return {
      result: '',
      searchDialog: false,
      search: ''
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'ecommerce/loadingSearchState',
      results: 'ecommerce/getSearchResults',
    }),
  },
  methods: {
    doSearch() {
      if (this.search) {
        this.searchDialog = true
        this.$store.dispatch('ecommerce/searchOrder', this.search)
      }
    },
    go(id) {
      if (id) {
        this.$router.push({
          name: 'ecommerce-order-details',
          params: {
            id: id
          }
        })
      }
    },
  },
  watch: {
    results(value) {
      console.log('value ' , value)
      if (value && value.id) {
        this.go(value.id)
      } else {
        this.$store.dispatch('snackbar/showSnackBar', {message: this.$t('commons.errors.no-results'), type: 'error'})
      }
    }
  }

}
</script>

<style scoped>

</style>