<template>
<div class="">
  <div class="d-flex justify-space-between">
    <h3>
      {{ $t('ecommerce.orders.management') }}
    </h3>
    <status-help></status-help>
  </div>

    <div>
    <template v-if="item.status === 'on_hold'">
      <p v-if="order.paid === 1" v-html="$t('ecommerce.orders.actions.on_hold.help_text')"></p>
      <p v-if="order.paid === 0" v-html="$t('ecommerce.orders.actions.on_hold_not_payed.help_text')"></p>
      <v-btn :loading="isPatching" @click="patchOrder" color="success">{{ $t('ecommerce.orders.actions.on_hold.button') }}</v-btn>
    </template>

    <template v-if="item.status === 'processing'">
      <h3 v-html="$t('ecommerce.orders.actions.processing.at', {time: $parseTime(item.processing_at), date: $parseDate(item.processing_at)})"></h3>
      <v-btn :loading="isPatching" @click="patchOrder" color="success" class="mt-3">{{ $t('ecommerce.orders.actions.processing.button') }}</v-btn>
    </template>

    <template v-if="item.status === 'ready'">
      <h3 v-html="$t('ecommerce.orders.actions.ready.at', {time: $parseTime(item.ready_at), date: $parseDate(item.processing_at)})"></h3>
      <v-btn :loading="isPatching" @click="patchOrder" color="success" class="mt-3">{{ $t('ecommerce.orders.actions.ready.button') }}</v-btn>
    </template>

    <template v-if="item.status === 'delivered'">
      <h3 v-html="$t('ecommerce.orders.actions.delivered.at', {time: $parseTime(item.delivered_at), date: $parseDate(item.processing_at)})"></h3>
    </template>
    </div>
  </div>


</template>

<script>
import {mapGetters} from "vuex";
import StatusHelp from "@/components/ecommerce/_partials/StatusHelp";
import {EventBus} from "@/event-bus";

export default {
  name: "OrderAction",

  components: {StatusHelp},

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      order: 'ecommerce/getOrder',
      isPatching: 'ecommerce/isPatching',
    }),
  },

  methods: {
    async patchOrder() {
      await this.$confirm(this.$t('ecommerce.orders.details.confirms.' + this.item.status)).then(res => {
        console.log('confirm res ' , res)
        if (res) {
          this.$store.dispatch('ecommerce/updateOrder', {
            id: this.item.id,
            status: this.item.status
          })
              .then(resp => {
                console.log('then close dialog resp ', resp)
                EventBus.$emit('reload-orders')
              })
              .catch(err => {
                console.log('create error err ', err)
              })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>