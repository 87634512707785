<template>
  <div>
    <div class="d-flex justify-space-between">
      <h3>{{ $t('ecommerce.orders.details.notes.title') }}</h3>
      <v-icon @click="openNoteModal">{{ $t('notes.create.icon') }}</v-icon>
    </div>
    <v-divider class="my-3" style="margin: 3px 0;"></v-divider>



      <div v-if="loadingNotes" align="center" class="py-5">
        <v-progress-circular indeterminate size="32"></v-progress-circular>
      </div>

      <v-sheet v-else elevation="1" class="px-4 py-2 mb-5" v-for="note in notes" :key="note.id">
        <div class="d-flex justify-space-between">
          <span>
            {{ $parseDateTime(note.created_at) }}
          </span>
          <span>
            {{ note.created_by }}
          </span>
        </div>
        <v-divider class="my-2"></v-divider>
        {{ note.note }}

      </v-sheet>

    <v-dialog
        v-model="dialog"
        max-width="700px"
        persistent
    >
      <v-card>
        <v-card-title>
          {{ formTitle }}
        </v-card-title>

        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-container>

            <v-row>
              <v-col cols="12">
                <v-textarea
                    v-model="note.note"
                    :rules="requiredRules"
                    :label="$t('notes.fields.note')"
                    required
                    outlined
                    dense
                    rows="3"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row>
              <v-col>
                <dialog-buttons
                    :valid="valid"
                    :is-loading="isLoading"
                    @save="storeNote"
                    @close="close"
                ></dialog-buttons>
              </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import clonedeep from 'lodash.clonedeep'
import DialogButtons from "@/components/form/DialogButtons";
import {mapGetters} from "vuex";
import {EventBus} from "@/event-bus";
import {validationRules} from "@/mixins/validationRules";

export default {
  name: "Form",

  components: {DialogButtons},

  mixins: [validationRules],

  props: {
    notes: {
      type: Array
    },
    model: {
      type: String,
      required: true
    },
    itemId: {
      type: [Number, String],
      required: true
    },
    loadingNotes: {
      type: Boolean,
      default: false
    }
  },

  data: (self) => ({
    note: {},
    defaultNote: {
      note: '',
      model: self.model,
      itemId: self.itemId
    },
    isEditing: false,
    dialog: false,
    valid: false
  }),

  mounted() {

    let self = this

    this.note = clonedeep(this.defaultNote)

    EventBus.$on('edit-note', function (note) {
      self.avatar = JSON.parse(JSON.stringify(note))
      self.isEditing = true
      self.dialog = true
    })
  },

  computed: {
    ...mapGetters({
      isLoading: 'note/loadingState'
    }),
    formTitle() {
      return (this.isEditing === true) ? this.$t('notes.edit.title') : this.$t('notes.create.title')
    },
  },

  methods: {

    async storeNote() {
      const valid = await this.$refs.form.validate()

      if (valid) {
        if (!this.isEditing) {
          await this.$store.dispatch('note/add', this.note)
              .then(resp => {
                console.log('then close dialog resp ', resp)
                this.close()
                this.$emit('note-created')
                // this.$store.dispatch('ecommerce/fetchOrder', this.itemId)
              })
              .catch(err => {
                console.log('create error err ', err)
              })
        } else {
          await this.$store.dispatch('note/update', this.note)
              .then(resp => {
                console.log('then close dialog resp ', resp)
                this.close()
              })
              .catch(err => {
                console.log('create error err ', err)
              })
        }
      }
    },

    openNoteModal() {
      this.dialog = true
    },

    close() {
      this.dialog = false
      this.valid = true
      this.$refs.form.reset()
      this.$nextTick(() => {
        this.isEditing = false
        this.note = clonedeep(this.defaultNote)
      })
    },
  }
}
</script>

<style scoped>
.bordered {
  border: 1px solid #dddddd;
}

</style>