<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>
          <v-icon>{{ $t('icons.filters') }}</v-icon>
          {{ $t('commons.filters.title') }}
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col md="3" sm="12">
            <datepicker
                id="start"
                :date.sync="localFilters.start"
                :label="$t('missions.fields.start')"
                :max="localFilters.end"
                :min="minDate"
            ></datepicker>
          </v-col>
          <v-col md="3" sm="12">
            <datepicker
                id="end"
                :date.sync="localFilters.end"
                :label="$t('missions.fields.end')"
                :max="maxDate"
                :min="localFilters.start"
            ></datepicker>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" sm="12">
            <order-statuses
                :statuses.sync="localFilters.statuses"
            ></order-statuses>
          </v-col>
          <v-col md="6" sm="12" v-if="showStoreFilter">
            <v-select
                v-if="!isStoreUser"
                v-model="localFilters.store"
                :label="$t('ecommerce.orders.filters.store')"
                :items="stores"
                item-text="name"
                item-value="store_code"
                outlined
                dense
                clearable
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="3" sm="12">
            <v-btn color="primary" block :loading="isLoading" @click="setDateRange">{{
                $t('commons.actions.filter')
              }}
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

import Datepicker from "@/components/form/Datepicker";
import clonedeep from "lodash.clonedeep";
import OrderStatuses from "@/components/form/ecommerce/OrderStatuses";
import moment from "moment";
import {mapGetters} from "vuex";

export default {
  name: "OrderFilters",

  components: {Datepicker, OrderStatuses},

  props: {
    filters: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    showStoreFilter: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      localFilters: {},
      maxDate: moment().subtract('1', 'day').toISOString().substr(0, 10),
    }
  },

  mounted() {
    this.localFilters = clonedeep(this.filters)
  },

  computed: {
    ...mapGetters({
      // filters: 'ecommerce/getFilters',
      stores: 'ecommerce/getStores',
      isStoreUser: 'auth/isStoreUser',
    }),
    minDate() {
      return (this.localFilters.end) ? moment(this.localFilters.end).subtract('30', 'day').toISOString().substr(0, 10) : moment().subtract('30', 'day').toISOString().substr(0, 10)
    }
  },

  methods: {
    setDateRange() {
      this.$store.commit('ecommerce/filters', this.localFilters)
      this.$emit('click')
    }
  },

  beforeDestroy() {
    this.$store.commit('ecommerce/resetFilters')
  },

  watch: {
    "localFilters": {
      handler: function () {
        this.$emit('update:filters', this.localFilters)
      },
      deep: true
    },
    "localFilters.store": {
      handler: function (val) {
        if (val === undefined) {
          this.localFilters.store = ''
          // this.setDateRange()
        }
      },
    }
  }
}
</script>

<style scoped>

</style>