<template>
  <span :class="{'d-block':dBlock}">
    <v-icon small :color="(val) ? 'success' : 'warning'" class="font-weight-bold"> {{ (val) ? 'mdi-check' : 'mdi-close'}}</v-icon> {{ label }}
  </span>
</template>

<script>
  export default {
    name: "Check",

    props: {
      label: {
        type: String
      },
      val: {
        type: [Number, Boolean],
        required: true
      },
      dBlock: {
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style scoped>

</style>