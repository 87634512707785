<template>
  <v-sheet class="mb-3 pa-3 d-flex flex-row">
    <v-img class="mr-5" v-if="product.image" :width="100" :max-width="100" height="auto"
           :src="product.image.url_hdpi"></v-img>
    <div>
      <strong>{{ product.title }}</strong><br>
      {{ $t('ecommerce.orders.details.product.quantity') }}: {{ product.quantity }}<br>
      {{ $t('ecommerce.orders.details.product.price') }}: {{
        $n(product.price, 'currency', country)
      }}<br>
      {{ $t('ecommerce.orders.details.product.total_price') }}:
      {{ $n(product.total_price, 'currency', country) }}<br>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: "Product",

  props: {
    product: {
      type: Object,
      required: true
    },
    country: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>