<template>
  <v-card class="mb-10">
    <v-card-title class="py-1">
      <h4 class="title">{{ $t('ecommerce.orders.details.transaction.title') }}</h4>
    </v-card-title>
    <v-card-text v-if="order">
      <p class="mt-3">
        {{ $t('ecommerce.orders.details.transaction.payment_by') }}:
        <strong>{{ $t('ecommerce.orders.details.transaction.methods.' + order.payment_method) }}</strong><br>
        {{ $t('ecommerce.orders.details.transaction.hash_id') }}: <strong>{{ order.hash_id }}</strong><br>
        <template v-if="order.payment_method !== 'cash'">
          {{ $t('ecommerce.orders.details.transaction.transaction_id') }}: <strong>{{
            order.transaction_id
          }}</strong><br>
          {{ $t('ecommerce.orders.details.transaction.transaction_date') }}: <strong>{{
            $parseDateTime(order.on_hold_at)
          }}</strong><br>
        </template>
      </p>
      <v-divider class="my-3"></v-divider>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Transaction",
  computed: {
    ...mapGetters({
      isLoading: 'ecommerce/loadingDetails',
      order: 'ecommerce/getOrder',
    }),
  }
}
</script>

<style scoped>

</style>