<template>
  <v-card class="mt-10">
    <v-card-title class="py-1">
      <h4 class="title">{{ $t('ecommerce.orders.details.discount.title') }}</h4>
    </v-card-title>
    <v-card-text v-if="order.discount_code">
      <p class="mt-3">
       <strong> {{ $t('ecommerce.orders.details.discount.code') }} </strong>
        <v-chip class="ma-2" small color="primary">{{order.discount_code}}</v-chip><br>
        <strong> {{ $t('ecommerce.orders.details.discount.total') }}: </strong>
           {{ $n(order.total_discount, 'currency', order.country) }}
      </p>
      <v-divider class="my-3"></v-divider>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DiscountCode",
  computed: {
    ...mapGetters({
      isLoading: 'ecommerce/loadingDetails',
      order: 'ecommerce/getOrder',
    }),
  }
}
</script>

<style scoped>

</style>