var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-5",attrs:{"flat":""}},[(_vm.showTitle)?_c('v-card-title',{staticClass:"title d-flex justify-space-between"},[_c('div',[_vm._v(" "+_vm._s(_vm.title)+" ")]),(Object.keys(_vm.orders).length > 0 && _vm.hasReportDownload)?_c('report-download',{attrs:{"type":"orders","url":"ecommerce/orders/report","is-loading":_vm.isLoading,"stats-range":_vm.filters}}):_vm._e()],1):_vm._e()],1),(_vm.hasFilters)?_c('order-filters',{attrs:{"filters":_vm.filters,"is-loading":_vm.isLoading,"show-store-filter":!_vm.isStoreDetails},on:{"update:filters":function($event){_vm.filters=$event},"click":_vm.getOrders}}):_vm._e(),_c('div',{staticClass:"d-flex justify-end"},[(_vm.hasSearch)?_c('search-order'):_vm._e()],1),_c('v-divider',{staticClass:"my-3"}),_c('v-data-table',{staticClass:"elevation-0 clickable",attrs:{"loading":_vm.isLoading,"loading-text":_vm.$t('commons.loading'),"headers":_vm.headers,"items":_vm.orders,"items-per-page":_vm.pagination.per_page,"hide-default-footer":true,"disable-pagination":"","expanded":_vm.expanded,"show-expand":"","single-expand":true},on:{"update:expanded":function($event){_vm.expanded=$event},"item-expanded":_vm.getOrderDetails},scopedSlots:_vm._u([{key:"item.rif_order",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.rif_order))])]}},{key:"item.user.fullname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.firstname)+" "+_vm._s(item.user.lastname)+" ")]}},{key:"item.on_hold_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseDateTime(item.on_hold_at))+" ")]}},{key:"item.total_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.total_amount, 'currency', item.country))+" ")]}},{key:"item.paid",fn:function(ref){
var item = ref.item;
return [_c('check',{attrs:{"val":item.paid}})]}},{key:"item.deliver",fn:function(ref){
var item = ref.item;
return [_c('check',{attrs:{"val":item.deliver === 'pickup'}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":item.color,"label":"","text-color":"white"}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('order-details',{attrs:{"item":_vm.order,"permit-actions":_vm.permitActions}})],1)]}}])}),_c('v-divider'),_c('div',{staticClass:"text-center py-3"},[_c('v-pagination',{attrs:{"length":_vm.pagination.total,"total-visible":5,"disabled":_vm.isLoading},on:{"input":function($event){return _vm.getOrders()}},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}})],1),_c('json-debug',{attrs:{"objs":[{orders: _vm.orders}]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }