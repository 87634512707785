<template>
  <div>

    <v-card class="mb-5" flat>
      <v-card-title v-if="showTitle" class="title d-flex justify-space-between">
        <div>
          {{ title }}
        </div>
        <report-download v-if="Object.keys(orders).length > 0 && hasReportDownload"
                         type="orders"
                         url="ecommerce/orders/report"
                         :is-loading="isLoading"
                         :stats-range="filters"
        ></report-download>
      </v-card-title>
    </v-card>

    <order-filters v-if="hasFilters"
                   :filters.sync="filters"
                   @click="getOrders"
                   :is-loading="isLoading"
                   :show-store-filter="!isStoreDetails"
    ></order-filters>

    <div class="d-flex justify-end">
      <search-order v-if="hasSearch"></search-order>
    </div>

    <v-divider class="my-3"></v-divider>

    <v-data-table
        :loading="isLoading"
        :loading-text="$t('commons.loading')"
        :headers="headers"
        :items="orders"
        :items-per-page="pagination.per_page"
        :hide-default-footer="true"
        class="elevation-0 clickable"
        disable-pagination
        :expanded.sync="expanded"
        show-expand
        :single-expand="true"
        @item-expanded="getOrderDetails"
      >

      <template v-slot:item.rif_order="{ item }" :class="item.color">
        <strong>{{ item.rif_order }}</strong>
      </template>

      <template v-slot:item.user.fullname="{ item }">
        {{ item.user.firstname }} {{ item.user.lastname }}
      </template>

      <template v-slot:item.on_hold_at="{ item }">
        {{ $parseDateTime(item.on_hold_at) }}
      </template>

      <template v-slot:item.total_amount="{ item }">
        {{ $n(item.total_amount, 'currency', item.country) }}
      </template>

      <template v-slot:item.paid="{ item }">
        <check
            :val="item.paid"
        ></check>
      </template>

      <template v-slot:item.deliver="{ item }">
        <check
            :val="item.deliver === 'pickup'"
        ></check>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip
            class="ma-2"
            :color="item.color"
            label
            text-color="white"
        >
          {{ item.status }}
        </v-chip>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <order-details :item="order"
                         :permit-actions="permitActions"
          ></order-details>
        </td>
      </template>

    </v-data-table>

    <v-divider></v-divider>

    <div class="text-center py-3">
      <v-pagination
          v-model="pagination.current"
          :length="pagination.total"
          :total-visible="5"
          @input="getOrders()"
          :disabled="isLoading"
      ></v-pagination>
    </div>

    <json-debug :objs="[{orders: orders}]"></json-debug>
  </div>
</template>

<script>

import OrderDetails from "@/components/ecommerce/_partials/OrderDetails";
import OrderFilters from "@/components/commons/OrderFilters";
import ReportDownload from "@/views/analytics/_partials/ReportDownload";
import SearchOrder from "@/components/commons/SearchOrder";
import Check from "@/components/commons/Check";
import {mapGetters} from "vuex";
import {EventBus} from "@/event-bus";
import moment from "moment";
import apiClient from "@/plugins/apiClient";

export default {
  name: "List",

  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    isStoreDetails: {
      type: String,
      default: ''
    },
    hasTitle: {
      type: String,
    },
    hasFilters: {
      type: Boolean,
      default: true
    },
    fullPaginatedList: {
      type: Boolean,
      default: false
    },
    hasSearch: {
      type: Boolean,
      default: true
    },
    hasReportDownload: {
      type: Boolean,
      default: true
    },
    statuses: {
      type: Array,
      default: () => {
        return []
      }
    },
    filterForUser: {
      type: String,
      default: ''
    },
    permitActions: {
      type: Boolean,
      default: true
    }
  },

  components: {
    OrderDetails,
    OrderFilters,
    ReportDownload,
    Check,
    SearchOrder
  },

  data(self) {
    return {
      isLoading: false,
      filterMenu: false,
      orders: [],
      order: {},
      filters: {
        start: '',
        end: '',
        statuses: [],
        store: self.isStoreDetails,
        user: self.filterForUser
      },
      pagination: {
        current: 1,
        total: 0,
        per_page: 10
      },
      expanded: [],
      headers: [
        {
          text: this.$t('ecommerce.orders.fields.rif_order'),
          value: 'rif_order',
          sortable: false,
          width: '10%',
          class: 'custom-class'
        },
        {text: this.$t('ecommerce.orders.fields.store'), value: 'store', sortable: false},
        {text: this.$t('ecommerce.orders.fields.user.fullname'), value: 'user', sortable: false},
        {text: this.$t('ecommerce.orders.fields.on_hold_at'), value: 'on_hold_at', sortable: false, width: '15%'},
        {text: this.$t('ecommerce.orders.fields.total_amount'), value: 'total_amount', sortable: false, width: '8%'},
        {text: this.$t('ecommerce.orders.fields.paid'), value: 'paid', sortable: false, width: '3%', align: "center"},
        {
          text: this.$t('ecommerce.orders.fields.deliver'),
          value: 'deliver',
          sortable: false,
          width: '3%',
          align: "center"
        },
        {text: this.$t('commons.fields.status'), value: 'status', sortable: false, width: '10%'},
        {text: '', value: 'data-table-expand'},
      ]
    }
  },

  mounted() {
    let self = this

    this.reset()

    this.filters.statuses = this.statuses

    this.getOrders()
    this.getOrderStatuses()
    this.getEnabledStores()

    if (this.isStoreUser) {
      self.headers.splice(1, 1)
    }

    EventBus.$on('reload-orders', ()=> {
      self.getOrders()
    })
  },

  methods: {
    reset() {
      this.$store.dispatch('ecommerce/reset', this.filters)
    },

    async getOrders() {
      this.isLoading = true
      return new Promise((resolve, reject) => {

        apiClient.get('ecommerce/orders?page=' + this.pagination.current + '&start=' + this.filters.start + '&end=' + this.filters.end + '&statuses=' + this.filters.statuses + '&store=' + this.filters.store + '&user=' + this.filters.user)
            .then(resp => {
              this.isLoading = false
              this.orders = resp.data.data
              this.pagination.current = resp.data.meta.current_page
              this.pagination.total = resp.data.meta.last_page
              this.pagination.per_page = resp.data.meta.par_page
            })
            .catch(err => {
              this.isLoading = false
              reject(err);
            });
      });
    },

    getOrderDetails(event) {
      console.log('event ', event)
      if (event.value) {
        this.$store.dispatch('ecommerce/fetchOrder', event.item.id)
      }
    },

    getOrderStatuses() {
      if (Object.keys(this.allStatuses).length === 0) {
        this.$store.dispatch('ecommerce/fetchOrderStatuses')
      }
    },

    getEnabledStores() {
      if (Object.keys(this.stores).length === 0) {
        this.$store.dispatch('ecommerce/fetchEnabledStores')
      }
    },

    details(v) {
      this.$router.push({
        name: 'order-show',
        params: {
          id: v.id,
          name: v.name
        }
      })
    },

    editOrder(order) {
      console.log('edit order ', order)
      this.isEditing = true
      EventBus.$emit('edit-order', order)
      this.dialog = true
    },

    couponDetails(order) {
      this.$router.push({
        name: 'order-details',
        params: {
          id: order.id
        }
      })
    }
  },

  computed: {
    ...mapGetters({
      isStoreUser: 'auth/isStoreUser',
      allStatuses: 'ecommerce/getStatuses',
      stores: 'ecommerce/getStores',
    }),

    title() {
      // return (!this.hasTitle) ? this.$t('ecommerce.orders.list_title', {start: this.$parseDate(this.filters.start), end: this.$parseDate(this.filters.end)}) : this.hasTitle
      return this.$t('ecommerce.orders.title')
    }
  }
}
</script>

<style lang="scss">
.v-data-table__expanded__row {
  background-color: #3B190C !important;
  color: white;

  button {
    color: white !important;
  }
}
</style>
