<template>
  <div>

    <div v-if="$router.currentRoute.name === 'ecommerce-order-details' && !isLoadingDetails">
      <v-btn color="primary" @click="$router.back()">{{ $t('ecommerce.orders.details.backToList') }}</v-btn>
    </div>

    <div v-if="isLoadingDetails" align="center" class="py-5">
      <v-progress-circular indeterminate size="32"></v-progress-circular>
    </div>

    <v-card v-if="!isLoadingDetails" :class="'my-4 border-' + order.status">
      <v-card-title class="flex justify-space-between">
        <h2 class="title">{{ $t('ecommerce.orders.fields.rif_order') }}: <strong>{{ order.rif_order }}</strong>
        </h2>
        <v-chip
            v-if="order.paid === 0"
            class="font-weight-bold"
            color="error"
            label
            text-color="white"
        >
          {{ $t('ecommerce.orders.details.not_pay_notice') }}
        </v-chip>
        <div class="no-print" v-if="permitActions">
          <v-chip
              class="font-weight-bold"
              color="primary"
              label
              text-color="white"
          >
            {{
              order.paid === 1 ? $t('ecommerce.orders.details.total_paid') : $t('ecommerce.orders.details.total_to_pay')
            }}: {{ $n(order.total_amount, 'currency', order.country) }}
          </v-chip>
          <v-icon @click="print" class="ml-5">{{ $t('icons.print') }}</v-icon>
          <v-icon v-if="$router.currentRoute.name !== 'ecommerce-order-details'" @click="details(order.id)"
                  class="ml-5">{{ $t('icons.details') }}
          </v-icon>
        </div>
      </v-card-title>

      <v-card-text>
        <order-progress :status="order.status" v-if="order.status && order.status !== 'cancelled'"></order-progress>

        <v-alert color="error"
                 class="mt-5 white--text"
                 v-if="order.status === 'cancelled'"
                 dense
        >
          <h3 v-html="$t('ecommerce.orders.actions.delete.at', {time: $parseTime(order.deleted_at), date: $parseDate(order.deleted_at)})"></h3>
        </v-alert>

        <v-row>
          <v-col class="justify-space-between">
            <customer :item="order"></customer>

            <transaction></transaction>

            <v-card v-if="permitActions"
                    color="primary"
                    class="lighten-5"
            >
              <v-card-text>
                <order-action :item="order"
                ></order-action>

                <v-divider class="my-5"></v-divider>

                <form-note v-if="order.id"
                           model="Ecommerce\Order"
                           :item-id="order.id"
                           :notes="notes"
                           :loading-notes="isLoadingNotes"
                           @note-created="reloadNotes"
                ></form-note>
              </v-card-text>
            </v-card>

            <delete-order v-if="order.can_be_deleted && permitActions"
                          :item="order"
            ></delete-order>

          </v-col>
          <v-col>
            <products v-if="order.products && order.country"
                      :products="order.products"
                      :country="order.country"
            ></products>

            <discountCode v-if="order.discount_code" ></discountCode>
            
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

    <div class="d-none" id="to-print">
      <v-card flat>
        <v-card-title>
          <h2 class="title">{{ $t('ecommerce.orders.fields.rif_order') }}: <strong>{{ order.rif_order }}</strong>
          </h2>
        </v-card-title>
        <v-card-text>
          <customer :user="order.user"></customer>

          <v-divider class="py-10" style="margin: 10px 0;"></v-divider>

          <transaction></transaction>

          <v-divider class="py-10" style="margin: 10px 0;"></v-divider>

          <products v-if="order.products && order.country"
                    :products="order.products"
                    :country="order.country"
          ></products>

          <v-divider class="py-10" style="margin: 10px 0;"></v-divider>

        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>

import OrderAction from "@/components/ecommerce/_partials/OrderAction";
import Products from "@/components/ecommerce/_partials/Products";
import Customer from "@/components/ecommerce/_partials/Customer";
import OrderProgress from "@/components/ecommerce/_partials/OrderProgress";
import FormNote from "@/components/notes/FormNote";
import Transaction from "@/components/ecommerce/_partials/Transaction";
import DiscountCode from "@/components/ecommerce/_partials/DiscountCode";
import DeleteOrder from "@/components/ecommerce/_partials/DeleteOrder";
import {mapGetters} from "vuex";

export default {
  name: "OrderDetails",

  components: {OrderAction, Products, Customer, OrderProgress, FormNote, Transaction, DeleteOrder,DiscountCode},

  props: {
    permitActions: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters({
      isLoadingDetails: 'ecommerce/loadingDetails',
      order: 'ecommerce/getOrder',
      notes: 'ecommerce/getOrderNotes',
      isLoadingNotes: 'ecommerce/isLoadingNotes'
    }),
  },

  methods: {
    print() {
      // Pass the element id here
      this.$htmlToPaper('to-print');
    },

    details(id) {
      if (id) {
        this.$router.push({
          name: 'ecommerce-order-details',
          params: {
            id: id
          }
        })
      }
    },

    reloadNotes() {
      this.$store.dispatch('ecommerce/fetchOrderNotes', this.order.id)
    }
  }
}
</script>

<style lang="scss">

@media print {
  .no-print {
    display: none !important;
  }
}

$statusColors: (
    "cart": "#9E9E9E",
    "on_hold": "#03A9F4",
    "processing": "#FF9800",
    "ready": "#8BC34A",
    "delivered": "#607D8B",
    "cancelled": "#FF5722",
);

@each $status, $color in $statusColors {
  .border-#{$status} {
    border-left: 5px solid #{$color} !important;

    .v-card__title {
      border-bottom: 1px solid #{$color} !important;
    }
  }
}
</style>